import useMediaQuery from "../../hooks/useMediaQuery";
import FooterContactList from "../../content/FooterContent/FooterContactList";
import FooterPodcastList from "../../content/FooterContent/FooterPodcastList";
import FooterBookList from "../../content/FooterContent/FooterBookList";
import FooterCourseList from "../../content/FooterContent/FooterCourseList";
import FooterMobile from "./FooterMobile";

export default function Footer() {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <footer className="bg-gradient-to-b from-primary">
      {isDesktop ? (
        <div className="container mx-auto flex flex-wrap justify-center gap-24 pb-24">
          <FooterContactList />
          <FooterPodcastList />
          <FooterBookList />
          <FooterCourseList />
        </div>
      ) : (
        <FooterMobile />
      )}
    </footer>
  );
}
