import SchoolIcon from "@mui/icons-material/School";
import { FooterListButton } from "../../components/UIComponents/Buttons";
import { FooterListTitle } from "../../components/UIComponents/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function FooterCourseList() {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <ul className="prose dark:prose-invert max-w-none prose-reset-list prose-hr:m-0 prose-link prose-a:font-normal w-full lg:w-64 m-0 p-0">
      <FooterListTitle className={isDesktop ? "" : "hidden"}>
        Courses
      </FooterListTitle>

      <FooterListButton href="https://www.forgottenadoptionoption.com/course">
        <SchoolIcon />
        Foster Care Adoption Simplified
      </FooterListButton>
    </ul>
  );
}
