import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import D3Map from "../components/D3Map";
import { iData } from "../types/d3mapTypes";
import { iMapData } from "../types/dataStoreTypes";
import { DbGetData } from "../utility/firebase";
import ErrorPopUp from "../components/ErrorPopUp";
import useElementSize from "../hooks/useElementSize";
import { Container } from "../components/UIComponents/Container";
import { ArticleHeader } from "../components/UIComponents/Typography";
import { DataContext } from "../components/DataStoreProvider";
import useMediaQuery from "../hooks/useMediaQuery";

export default function Map() {
  const [mapData, setMapData] = useState<iMapData[]>([]);
  const [mapContainerRef, size] = useElementSize();
  const [selectedData, setSelectedData] = useState<iMapData>();
  const { appState, dispatch } = useContext(DataContext);
  const [error, setError] = useState("");

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const getMapDataFromDb = async () => {
    try {
      return await DbGetData("d3Data", "mapData");
    } catch (e) {
      if (e instanceof Error) setError(e.message);
      return null;
    }
  };

  useEffect(() => {
    if (appState.mapData != null) {
      setMapData(appState.mapData);
    } else {
      const setMapDataAsync = async () => {
        const mapDataFromDb = await getMapDataFromDb();

        dispatch({
          type: "SET_MAP_DATA",
          payload: {
            mapData: mapDataFromDb?.data,
          },
        });
      };

      setMapDataAsync();
    }
  }, [appState]);

  useEffect(() => {
    if (mapData != null) {
      // const total = mapData
      //   .map((data: iMapData) => data.number)
      //   .reduce((prev, curr) => prev + curr, 0);

      setSelectedData({
        state: "United States of America",
        number: 108000,
        links: ["https://heartgalleryofamerica.org/waiting-children/"],
      } as iMapData);
    } else {
      setSelectedData({
        state: "United States of America",
        number: 108000,
        links: ["https://heartgalleryofamerica.org/waiting-children/"],
      } as iMapData);
    }
  }, [mapData]);

  const mapColorData = useMemo(() => {
    const data = mapData.map(entry => ({
      name: entry.state,
      value: entry.number,
    }));
    return data as iData[];
  }, [mapData]);

  const resetHandler = useCallback(() => {
    // const total = mapData
    //   .map((entry: iMapData) => entry.number)
    //   .reduce((prev, curr) => prev + curr, 0);
    setSelectedData({
      state: "United States of America",
      number: 108000,
      links: ["https://heartgalleryofamerica.org/waiting-children/"],
    } as iMapData);
  }, [mapData]);

  const clickHandler = useCallback(
    featureName => {
      const filteredData = mapData?.find(
        entry =>
          String(entry.state).toLowerCase() ===
          String(featureName).toLowerCase()
      );
      if (filteredData) setSelectedData(filteredData);
    },
    [mapData, setSelectedData]
  );

  return (
    <main className="MapContianer space-y-8" ref={mapContainerRef}>
      {error && <ErrorPopUp error={error} setError={setError} />}
      <div className="flex justify-center overflow-hidden">
        <D3Map
          width={size.width}
          height={isDesktop ? 500 : 350}
          geoJsonPath="./json/USGeoJson.json"
          numberOfBuckets={5}
          data={mapColorData}
          clickHandler={clickHandler}
          resetHandler={resetHandler}
          mapData={mapData}
          legendLabel="Number of children waiting for adoption"
        />
      </div>
      {selectedData && Object.keys(selectedData).length > 0 && (
        <Container className="max-w-[1200px] w-[95%] md:w-[80%] rounded-sm">
          <ArticleHeader>{selectedData?.state}</ArticleHeader>
          <div className="font-normal mt-2">
            Waiting Children{" "}
            <span className="text-primary font-bold text-2xl">
              {selectedData?.number}
            </span>
          </div>
          {selectedData?.links && (
            <div className="font-normal mt-2">
              Learn More
              <br />
              {selectedData.links.map((link: string) => (
                <div key={`link_${link}`}>
                  {link.includes("http") ? (
                    <a
                      key={`${link}`}
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:underline underline-offset-2 text-primary"
                    >
                      {link}
                    </a>
                  ) : (
                    <span className="text-primary font-bold">{link}</span>
                  )}
                </div>
              ))}
            </div>
          )}
        </Container>
      )}
    </main>
  );
}
